<template>
  <b-avatar-group
    v-if="absentees.length>0"
    size="70px"
  >
    <b-avatar
      v-for="(absentee,index) in absentees"
      :key="index"
      v-b-tooltip.hover
      class="pull-up"
      :title="`${absentee.name}, ${absentee.class} - Absented ${absentee.total_absents} times`"
      :src="getFullPath(absentee.photo)"
    />
  </b-avatar-group>
  <div
    v-else
    class="row justify-content-center"
  >
    <div class="col-12">
      <h3 style="text-align: center;">
        No Student
      </h3>
    </div>
  </div>
</template>

<script>
import { BAvatarGroup, BAvatar, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'AbsenteeGroup',
  components: {
    BAvatarGroup,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    absentees() {
      return this.$store.getters['dashboard/students']?.absentees
    },
  },
  methods: {
    getFullPath(photo) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${photo}`
    },
  },
}
</script>
