<script>
import { Bar } from 'vue-chartjs'

export default {
  name: 'AgeGroup',
  extends: Bar,
  data() {
    return {
      chartData: {
        labels: ['0 - 4 Years', '5 - 9 Years', '10 - 14 Years', '15 - 19 Years', '20 and Above'],
        datasets: [
          {
            borderWidth: 1,
            label: 'Males',
            backgroundColor: '#609220',
            data: [0, 0, 0, 0, 0],
          },
          {
            borderWidth: 1,
            label: 'Females',
            backgroundColor: '#f39900',
            data: [0, 0, 0, 0, 0],
          },
        ],
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  computed: {
    ageGroup() {
      return this.$store.getters['dashboard/students']?.ageGroup
    },
  },
  watch: {
    ageGroup(group) {
      this.chartData.datasets[0].data = group.males
      this.chartData.datasets[1].data = group.females
      this.renderChart(this.chartData, this.options)
    },
  },
}
</script>
