<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Absentees</b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!--        August 4th 2022, 7:09 AM-->
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <absentee-group />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody
} from 'bootstrap-vue'
import AbsenteeGroup from '@/views/dashboard/students/AbsenteeGroup'

export default {
  components: {
    AbsenteeGroup,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  data() {
    return {
    }
  },
}
</script>
