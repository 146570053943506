<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="12"
        cols="12"
      >
        <population />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
        cols="12"
      >
        <attendance />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="6"
        cols="12"
      >
        <absentees />
      </b-col>
      <b-col
        lg="6"
        cols="12"
      >
        <lenon-bar-chart />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import LenonBarChart from '@/views/dashboard/students/LenonBarChart.vue'
import Population from './Population.vue'
import Attendance from './Attendance.vue'
import Absentees from './Absentees.vue'
import { READ_STUDENT_DASHBOARD_DATA_Q } from '@/graphql/queries'
import logData from '@/libs/log'

export default {
  name: 'StudentsDashboard',
  components: {
    LenonBarChart,
    BRow,
    BCol,
    Attendance,
    Population,
    Absentees,
  },
  data() {
    return {
      // Area charts
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
    }
  },
  mounted() {
    this.fetchStudentDashboardData()
  },
  methods: {
    kFormatter,
    fetchStudentDashboardData() {
      this.$apollo.query({
        query: READ_STUDENT_DASHBOARD_DATA_Q,
      }).then(res => {
        if (!res.errors && res.data != null) {
          this.$store.commit('dashboard/setStudentsData', res.data)
        }
      }).catch(err => {
        logData(err)
      })
    },
  },
}
</script>
