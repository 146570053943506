<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Age Groups</b-card-title>
      <b-card-text class="mr-25 mb-0" />
    </b-card-header>
    <b-card-body class="statistics-body">
      <div class="row">
        <div class="col-12">
          <age-group />
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody,
} from 'bootstrap-vue'
import AgeGroup from '@/views/dashboard/students/AgeGroup.vue'

export default {
  components: {
    AgeGroup,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  data() {
    return {

    }
  },
}
</script>
